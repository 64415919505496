'use client'

import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/Sheet'
import SensayLogo from '@/public/logos/sensay-logo.svg'
import { List, X } from '@phosphor-icons/react/dist/ssr'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import type { HTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'
import UserProfileButton from '../UserProfileButton'
import { Button } from '../ui/Button'

interface IMenuBurger {
  title: string
  href: string
}

interface HeaderProps extends HTMLAttributes<HTMLDivElement> {
  isHeroVisible?: boolean
  enableResize?: boolean
  backgroundClass?: {
    solid: string
    transparent: string
  }
}

const menuBurger: IMenuBurger[] = [
  {
    title: 'About',
    href: '/about',
  },
  {
    title: 'Replicas',
    href: '/replicas',
  },
  {
    title: 'Pricing',
    href: '/pricing/year',
  },
  {
    title: 'Contact',
    href: '/contact',
  },
]

export default function Header({
  isHeroVisible = false,
  enableResize = false,
  backgroundClass = {
    solid: 'bg-background',
    transparent: 'bg-background-80',
  },
  className,
  ...props
}: HeaderProps) {
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY

      // changing the padding of the header changes currentScrollY value, so we need different values depending on the expanded state
      setIsScrolled((isScrolled) => currentScrollY > (isScrolled ? 160 : 200))
    }

    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <header
      className={twMerge(
        'sticky top-0 z-[110] w-full transition-all duration-300',
        !isScrolled ? backgroundClass.solid : backgroundClass.transparent,
        isScrolled && 'backdrop-blur-sm',
      )}
    >
      <nav
        className={twMerge(
          'container box-border flex flex-wrap md:grid md:grid-cols-[auto_1fr_auto] lg:grid-cols-3 items-center justify-between w-full transition-all duration-300',
          isScrolled || !enableResize ? 'py-2 sm:py-3' : 'py-6',
          className,
        )}
        {...props}
      >
        <div className="flex items-center gap-2 justify-start">
          <Link
            href="/"
            className="relative flex shrink-0 flex-row items-center gap-1 transition duration-300 hover:text-primary"
          >
            <SensayLogo alt="Sensay" className="h-[32px] fill-current" />
            <span className="beta-badge rounded-md bg-bright-plum-7 px-2 py-1 text-xs font-medium text-bright-plum ring-1 ring-inset ring-bright-plum-50">
              βeta
            </span>
          </Link>
        </div>

        <ul className="order-last min-h-11 hidden md:order-none md:flex justify-start md:relative md:top-0 md:flex-row md:pt-0 m-0 flex-col items-center gap-6 p-0 md:justify-center md:py-0">
          {menuBurger.map((item: IMenuBurger) => (
            <li className="list-none font-sourcecodepro" key={item.title}>
              <Link
                href={item.href}
                className="mb-6 text-center text-2xl transition duration-300 hover:text-primary md:mb-0 md:text-lg"
              >
                {item.title}
              </Link>
            </li>
          ))}
        </ul>
        <div className="justify-self-end flex items-center gap-2">
          <UserProfileButton buttonSize="small" />
          <Sheet>
            <MobileMenu />

            <SheetTrigger asChild>
              <Button
                className="rounded-sm md:hidden text-burgundy bg-bright-plum-7 p-1 border-none"
                id="header-disclosure-button"
                aria-label="Toggle menu"
                size="icon"
                variant="white"
              >
                <List aria-hidden="true" size={26} />
              </Button>
            </SheetTrigger>
          </Sheet>
        </div>
      </nav>
    </header>
  )
}

function MobileMenu() {
  return (
    <SheetContent className=" z-[1000] flex flex-col gap-12 bg-off-white" side="right">
      <SheetHeader className="flex flex-row justify-between items-center gap-10">
        <SheetTitle>
          <SensayLogo alt="Sensay" className="h-[32px] fill-current" />
        </SheetTitle>

        <SheetTrigger asChild>
          <Button variant="ghost" size="icon" className="!mt-0 text-burgundy">
            <X size={28} />
          </Button>
        </SheetTrigger>
      </SheetHeader>

      <ul className="flex flex-col items-start gap-8 p-0">
        {menuBurger.map((item: IMenuBurger) => (
          <li className="list-none font-sourcecodepro" key={item.title}>
            <Link
              href={item.href}
              className="mb-6 text-center text-2xl transition duration-300 hover:text-primary md:mb-0 md:text-lg"
            >
              {item.title}
            </Link>
          </li>
        ))}

        <UserProfileButton buttonSize="medium" />
      </ul>
    </SheetContent>
  )
}
