'use client'
import useIsMobile, { TABLET_BREAKPOINT } from '@/hooks/useIsMobile'
import { GTM_HEADER_MY_SENSAY_ID } from '@/lib/gtm'
import { signIn, signOut, useSession } from 'next-auth/react'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { useDisconnect } from 'wagmi'
import { Button } from './ui/Button'
import Spinner from './ui/Spinner'

function LoadingSessionButton() {
  return (
    <>
      <Button
        size="icon"
        onClick={() => {
          close()
          signIn()
        }}
        disabled
        className="md:hidden "
      >
        <Spinner className="[&_svg]:size-3" />
      </Button>

      <Button
        size="medium"
        onClick={() => {
          close()
          signIn()
        }}
        className="hidden md:flex"
        disabled
      >
        <Spinner text="Loading" />
      </Button>
    </>
  )
}

function SignOutButton({ isMobile }: { isMobile: boolean | undefined | null }) {
  const { disconnect } = useDisconnect()
  return (
    <Button
      size={isMobile ? 'small' : 'medium'}
      onClick={() => {
        disconnect()
        signOut()
      }}
    >
      Sign Out
    </Button>
  )
}

function MySensayButton({
  isMobile,
  userId,
  userPlan,
  userEmail,
}: {
  isMobile: boolean | undefined | null
  userId: string | undefined
  userPlan: string | undefined
  userEmail: string | undefined
}) {
  return (
    <Button
      asChild
      variant="secondary"
      size={isMobile ? 'small' : 'medium'}
      data-user-uuid={userId}
      data-user-plan={userPlan}
      data-user-email={userEmail}
      id={GTM_HEADER_MY_SENSAY_ID}
    >
      <Link href="/me">My Sensay</Link>
    </Button>
  )
}

function SignInButton({ size = 'medium' }: { size?: 'small' | 'medium' }) {
  return (
    <Button size={size} className="w-full" asChild>
      <Link href="/sign-in">Sign In</Link>
    </Button>
  )
}

function UserProfileButton({ buttonSize = 'small' }: { buttonSize?: 'small' | 'medium' }) {
  const session = useSession()
  const pathname = usePathname()

  const { status } = session
  const userId = session.data?.userId ?? undefined
  const userEmail = session.data?.user.email ?? undefined
  const userPlan = session.data?.customer.plan ?? undefined
  const isLoadingSession = status === 'loading'
  const isLoggedIn = status === 'authenticated'

  const hideSignInPaths = ['/sign-in']
  const signoutButtonPaths = ['/me']

  const hideSignIn = hideSignInPaths.includes(pathname)
  const showSignoutButton = isLoggedIn && signoutButtonPaths.includes(pathname)

  const isMobile = useIsMobile(TABLET_BREAKPOINT)

  if (isLoadingSession && !hideSignIn) {
    return <LoadingSessionButton />
  }

  if (isLoggedIn) {
    if (showSignoutButton) {
      return <SignOutButton isMobile={isMobile} />
    }

    return <MySensayButton isMobile={isMobile} userId={userId} userPlan={userPlan} userEmail={userEmail} />
  }

  if (!hideSignIn) {
    return <SignInButton size={buttonSize} />
  }

  return null
}

export default UserProfileButton
