'use client'
import { useLayoutEffect, useState } from 'react'

export const SM_BREAKPOINT = 640
export const MD_BREAKPOINT = 768
export const LG_BREAKPOINT = 1024
export const XL_BREAKPOINT = 1280
export const XXL_BREAKPOINT = 1536

export const MOBILE_BREAKPOINT = 425
export const TABLET_BREAKPOINT = MD_BREAKPOINT

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: typeof window !== 'undefined' ? window?.innerWidth : null,
    height: typeof window !== 'undefined' ? window?.innerHeight : null,
  })

  useLayoutEffect(() => {
    const handleResize = () => {
      if (typeof window !== 'undefined') {
        setWindowSize({
          width: window?.innerWidth,
          height: window?.innerHeight,
        })
      }
    }

    window?.addEventListener('resize', handleResize)

    handleResize()

    return () => window?.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}

const useIsMobile = (breakpoint = MOBILE_BREAKPOINT, dafaultValue = true) => {
  const { width } = useWindowSize()
  const [isMobile, setMobile] = useState<boolean | null>(width ? width <= breakpoint : dafaultValue)

  useLayoutEffect(() => {
    if (width !== null) {
      setMobile(width <= breakpoint)
    }
  }, [width, breakpoint])

  return isMobile
}

export function RenderBasedOnScreenWidth({
  renderSmall,
  renderLarge,
  breakpoint = MOBILE_BREAKPOINT,
}: { renderSmall: () => React.ReactNode; renderLarge: () => React.ReactNode; breakpoint?: number }) {
  const isMobile = useIsMobile(breakpoint)

  return isMobile ? renderSmall() : renderLarge()
}

export function useCurrentBreakpoint() {
  const { width } = useWindowSize()

  if (!width) return null
  if (width < SM_BREAKPOINT) return 'xs'
  if (width < MD_BREAKPOINT) return 'sm'
  if (width < LG_BREAKPOINT) return 'md'
  if (width < XL_BREAKPOINT) return 'lg'
  if (width < XXL_BREAKPOINT) return 'xl'
  return '2xl'
}

type BreakpointKey = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
type BreakpointValues<T> = Partial<Record<BreakpointKey, T>>

/**
 * A React hook that returns a value based on the current screen width breakpoint.
 *
 * @template T The type of values to be returned (can be number, string, object, etc.)
 * @param {BreakpointValues<T>} breakpointValues An object mapping breakpoints to values.
 *   Possible breakpoints are: 'xs' (<640px), 'sm' (640-767px), 'md' (768-1023px),
 *   'lg' (1024-1279px), 'xl' (1280-1535px), and '2xl' (≥1536px).
 *
 * @returns {T | undefined} The value corresponding to the current breakpoint.
 *   If the current breakpoint isn't specified, falls back to the closest smaller breakpoint's value.
 *   Returns undefined if window width is not available (during SSR) or no valid value is found.
 *
 * @example
 * // Basic usage with numbers
 * const columns = useValueBasedOn({ sm: 4, md: 6, lg: 8 })
 *
 */
export function useValueBasedOnBreakpoint<T>(breakpointValues: BreakpointValues<T>): T | undefined {
  const { width } = useWindowSize()

  if (!width) return undefined

  // Find the appropriate breakpoint value by checking from smallest to largest
  if (width < SM_BREAKPOINT && breakpointValues.xs !== undefined) return breakpointValues.xs
  if (width < MD_BREAKPOINT && breakpointValues.sm !== undefined) return breakpointValues.sm
  if (width < LG_BREAKPOINT && breakpointValues.md !== undefined) return breakpointValues.md
  if (width < XL_BREAKPOINT && breakpointValues.lg !== undefined) return breakpointValues.lg
  if (width < XXL_BREAKPOINT && breakpointValues.xl !== undefined) return breakpointValues.xl
  if (breakpointValues['2xl'] !== undefined) return breakpointValues['2xl']

  // If no matching breakpoint is found, return the value for the closest smaller breakpoint
  const breakpoints: BreakpointKey[] = ['2xl', 'xl', 'lg', 'md', 'sm', 'xs']
  return breakpoints.reduce<T | undefined>((acc, key) => {
    if (acc === undefined && breakpointValues[key] !== undefined) {
      return breakpointValues[key]
    }
    return acc
  }, undefined)
}

export default useIsMobile
